import React from 'react';
import {Container, Image} from "react-bootstrap";
import logo from "../assets/smart_logo.png";


class NavHeader extends React.Component {
    render() {
        return (
            <>
                <div className="client_secondary_bg">
                    <Container height="70px">
                        <div className="text-center">
                            <a href="/">
                                <Image id="logo2" 
                                style={{padding: "10px 0px", alignSelf: 'center', width: '60%'}} 
                                src={logo} />
                            </a>
                        </div>
                    </Container>
                </div>
                <hr className="mt-0 mb-0"/>
            </>
        );
    }
}
export default NavHeader;