import React from "react";
import {Col, Container} from "react-bootstrap";


class Greeting extends React.Component {
    render() {
        return (
            <Container>
                <Col xs={{span: 10, offset: 1}} md={{span: 4, offset: 4}}>
                    <p>Present this QR code at any Starbucks Philippines branch nationwide to claim a tall
                        handcrafted beverage* of your choice.</p>
                    <p>Enjoy your drink!</p>
                    <p>
                        <small><em>*Exclusive to For Here and To Go transactions. Upsize and add-ons may be applied, but
                            any amount in excess of P175 shall be shouldered by the customer. Terms and conditions apply.</em></small>
                    </p>
                </Col>
            </Container>
        );
    }
}

export default Greeting;